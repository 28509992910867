<template>
  <div class="index">
    <Form @submit="onSubmit">
      <h3>登录</h3>
      <Field
        v-model="data.account"
        name="用户名"
        label="用户名:"
        placeholder="用户名"
        :rules="[{ required: true, message: '请填写用户名' }]"
      />
      <Field
        v-model="data.password"
        type="password"
        name="密码"
        label="密码:"
        placeholder="密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <div style="margin: 16px;">
        <Button round block type="info" :loading = buttonLoading native-type="submit">
          提交
        </Button>
      </div>
    </Form>
  </div>
</template>

<script>
// @ is an alias to /src
import { Form,Field,Button,Toast } from 'vant';

export default {
  name: 'Index',
  components: {
    Form,Button,Field
  },
  data(){
    return {
      buttonLoading:false,
      data:{
        account:'',
        password:''
      }
    }
  },
  mounted(){
    
  },
  methods:{
    onSubmit(){
      var that = this
      this.buttonLoading = true
      console.log(this.data)
      this.axios.post('/api/investment/furniture_sign/login',this.data).then(function(response){
          that.buttonLoading = false
          if(response.data.code == 1){
              Toast.success('登录成功')
              that.$store.commit('setToken',response.data.data.token)
              setTimeout(()=>{
                  that.$router.push('/list')
              },1000)
          }else{
            Toast.fail(response.data.msg);
          }
      }).catch(function() {
        that.buttonLoading = false
      });
    }
  }
}
</script>
<style lang='scss' scoped>
.index{
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content:center;
  h3{
    line-height: 5rem;
  }
}
</style>
