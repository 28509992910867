import Vue from 'vue';
import axios from 'axios';
// Vue.use(axios);  axios不支持use安装
import Qs from 'qs'
// import { VIEW_LOADING } from '../store/types'
//拦截器
// 请求时
var instance = axios.create({
  // baseURL: 'https://managetest.hanlandglobal.cn',
  baseURL: 'https://manageusa.hanlandglobal.com',
  timeout: 5000,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});


instance.interceptors.request.use(function (config) {
  // 请求之前，做点事
  // store.dispatch(VIEW_LOADING,true)
  // config.data.token = 
  if (config.data) {
    config.data = Qs.stringify(config.data)
  }
  // store.commit('showLoading')
  return config;
}, function (error) {
  // 错误请求，做点事
  // store.commit('hideLoading')
  // alert('遇到意外错误，联系管理员反馈错误！', '意外错误', {
  //   confirmButtonText: '确定',
  //       callback: ()=> {

  //       }
  //   });
  return Promise.reject(error);
});

// 响应式，数据返回时
instance.interceptors.response.use(function (response) {
  // console.log('loading消失')
  // store.dispatch(VIEW_LOADING,false)
  // store.commit('hideLoading')
  return response;
}, function (error) {
  // console.log(11)
  // store.commit('hideLoading')
  // alert('遇到意外错误，联系管理员反馈错误！', '意外错误', {
  //   confirmButtonText: '确定',
  //       callback: ()=> {

  //       }
  //   });
  return Promise.reject(error);
});

Vue.prototype.$axios = instance;// 组件里面: this.$axios;
window.axios = instance; //组件，非组件里面都可以  axios()

export default instance;