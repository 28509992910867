import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta:{
      auth:false
    }
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('../views/List.vue'),
    meta:{
      auth:true
    }
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/Detail.vue'),
    meta:{
      auth:true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.meta.auth) {
    let token = store.state.TOKEN;
    if (token && token.length > 0) {
     next();
    } else {
     next('/');
    }
  }else{
    next();
  }
});
router.afterEach(() => {
  window.scrollTo(0,0);  
});
export default router
