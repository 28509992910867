import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    TOKEN:Cookies.get('token')||'',
  },
  mutations: {
    setToken(state,token){
      Cookies.set('token',token,{ expires: 1 })
      state.TOKEN = token
    },
  },
  actions: {
  },
  modules: {
  }
})
